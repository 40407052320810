import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    createPaymentSession(request) {
        return services.http.post(`/v1.0/checkout/create-session`, request);
    },
    createEventPaymentSession(request) {
        return services.http.post(`/v1.0/checkout/event-license`, request);
    },
    createOrgPaymentSession(request) {
        return services.http.post(`/v1.0/checkout/org-license`, request);
    },
    createOrgProductSession(request) {
        return services.http.post(`/v1.0/checkout/org-product`, request);
    },
    getSessionStatus(id) {
        return services.http.get(`/v1.0/checkout/sessions/${id}`);
    },
    getEventStatus(id) {
        return services.http.get(`/v1.0/checkout/events/${id}`);
    },
    getOrgStatus(id) {
        return services.http.get(`/v1.0/checkout/orgs/${id}/status`);
    },
    getOrgPaymentPortalLink(id) {
        return services.http.get(`/v1.0/checkout/orgs/${id}/portal`);
    },
}