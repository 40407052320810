<template>
  <v-row>
  <v-col 
    v-for="(img, idx) in logos"
    :key="idx"
    cols="6" 
    md="4">
    <v-sheet :elevation="1" class="text-center align-center pa-4" height="80">
      <img :src="`https://sodisp.imgix.net/web/sso/${img}?h=40`" :alt="img" class="d-inline-block" style="max-width:100%; max-height:40px;"/>
    </v-sheet>
  </v-col>
  </v-row>
</template>

<script>
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SectionCustomers",
  components: {
  },
  props: {
    
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
      logos: //tenant.id == 'cofi' 
                  /* ? */ ['app-store-and-google-play.png', 'apple-watch.png', 'strava-color.png', 'garmin-color.png', 'fitbit-color.jpg', 'oura-color.png', 'mapmyfitness-color.png', 'polar-color.png', 'suunto-color.png']
                   //:  ['app-store-and-google-play.png', 'strava-color.png', 'garmin-color.png', 'apple-watch.png', 'mapmyfitness-color.png', 'fitbit-color.jpg', 'polar-color.png', 'suunto-color.png', 'coros-color.png']
                   ,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>