<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <v-card-title class="headline">Subscription for {{org.name}}</v-card-title>

      <v-card-text>      
        <p>
          Upgrade, or downgrade, your subscription or purchase additional user licenses.
        </p>

        <p v-if="!orgUtil.isFreePlan()">
          <v-btn color="green" class="mr-2 mb-2" @click="redirectToPaymentPortal">View/download invoices</v-btn>
          <v-btn outlined color="green" class="mr-2 mb-2" @click="redirectToPaymentPortal">Manage Subscription</v-btn>
          <v-btn outlined color="green" class="mr-2 mb-2" @click="addSeats(org.license)">Add Seats</v-btn>
        </p>

        <p v-if="licenseInfo && licenseInfo.start_date && licenseInfo.renewal_date">
          This subscription started at <strong>{{licenseInfo.start_date | localDate('ymd')}}</strong> and will automatically renew at <strong>{{licenseInfo.renewal_date | localDate('ymd')}}</strong> (unless cancelled before this date).
        </p>

      </v-card-text>
      
      <v-alert v-if="paymentStatus && paymentStatus.status == 'OK'" type="success" tile>
        <p>Thanks! Your subscription was successfully updated.</p>
      </v-alert>
      <v-alert v-else-if="status == 'cancelled' || (paymentStatus && paymentStatus.status == 'ERROR')" type="error" tile>
        Payment was cancelled or unsuccessful.
      </v-alert>
      <v-alert v-else-if="status == 'check-payment'" type="info" tile>
        <v-progress-circular indeterminate :size="25" :width="5" color="white" class="mr-4"></v-progress-circular>
        Loading payment status... One minute...
      </v-alert>
      <v-alert v-else-if="status == 'check-subscription'" type="info" tile>
        <v-progress-circular indeterminate :size="25" :width="5" color="white" class="mr-4"></v-progress-circular>
        Loading subscription status... One minute...
      </v-alert>

      <div v-if="!status">
        <v-card-title class="subtitle">
          <span class="mr-2">Current Subscription:</span>
          <span class="mr-2 font-weight-bold">{{ org.license | license }}</span>
          <span v-if="licenseInfo" class="mr-2 font-weight-bold">({{ licenseInfo.max_capacity }} seats)</span>
        </v-card-title>
        <v-card-text v-if="licenseInfo">
          <v-alert v-if="!licenseInfo.valid" type="error" prominent>
            <p>Invalid or expired license. No new users can join this challenge until new seats are purchased.</p>
            <p v-if="org.license !== 'FREE'">
              <v-btn @click="selectPlan(org.license)">Purchase Seats</v-btn>                            
            </p>
          </v-alert>
          <div class="d-flex flex-row">
            <v-progress-linear striped class="ma--4" height="25" color="green" :value="Math.round(licenseInfo.current_capacity / licenseInfo.max_capacity * 100)" />
            <div class="shrink mt-0 ml-4" style="white-space:nowrap;">{{ licenseInfo.max_capacity }} users</div>
          </div>
          <span class="text-muted">{{ licenseInfo.current_capacity }} users out of {{ licenseInfo.max_capacity }} available users. Actual number of active users might have a slight processing delay.</span>
        </v-card-text>
      </div>

      <div class="align-center">
          <div class="text-muted">Currency:</div>
          <v-btn-toggle v-model="currency" mandatory class="multiline-btns" >
            <v-btn v-for="cur in currencies" :key="cur" :value="cur" small>
              {{cur}}
            </v-btn>
          </v-btn-toggle>
      </div>

      <v-row class="mx-2 pricing-details">
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="white lighten-2" class="pa-4">
            <h3 class="text-center">Free</h3>
            <p>Try out for yourself and up to 5 team members.</p>
            <v-btn v-if="org.license === 'FREE'" color=""  :to="{name: 'orgmanagerView', params: {id: org.id}}">Continue on Free</v-btn>
            <div v-else><br/><br/><br/></div>
            <hr/>
            <span class="large">FREE</span><span class="small">up to 5 users</span>
            <hr/>
            <ul class="check-list">
              <li>Up to 5 users</li>
              <li>All features from Standard plan</li>
              <li><router-link to="/microsoft-teams">Microsoft Teams Integration</router-link></li>
              <li><router-link to="/slack">Slack Integration</router-link></li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="5" outlined color="white lighten-0" class="pa-4">
            <h3 class="text-center">Standard</h3>
            <p>Create engaging corporate wellness challenges.</p>
            <v-btn v-if="org && org.license === 'BASIC'" outlined color="green" @click="addSeats(org.license)">Add Seats</v-btn>
            <v-btn v-else color="green" @click="selectPlan('subscription-month-basic')">Select Standard</v-btn>
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='subscription-month-basic').prices[currency]}}</span><span class="small">per user<br/>per month</span>
            <hr/>
            <ul class="check-list">
              <li>Up to 250 users</li>
              <li>Team leaderboards</li>
              <li>All challenge types</li>
              <li>All device intergrations</li>
              <li>Excel import and export</li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="5" outlined color="white lighten-0" class="pa-4 popular">
            <h3 class="text-center">Pro <v-chip small outlined color="black">Most Popular</v-chip></h3>
            <p>Advanced features to host engaging challenges.</p>
            <v-btn v-if="org && org.license === 'PRO'" outlined color="green" @click="addSeats(org.license)">Add Seats</v-btn>
            <v-btn v-else color="green" @click="selectPlan('subscription-month-pro')">Select Pro</v-btn>
            <hr/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='subscription-month-pro').prices[currency]}}</span><span class="small">per user<br/>per month</span>
            <hr/>
            <ul class="check-list">
              <li class="font-weight-bold">All features from Standard plan</li>
              <li>Unlimited users</li>
              <li><router-link to="/microsoft-teams">Microsoft Teams Integration</router-link></li>
              <li><router-link to="/slack">Slack Integration</router-link></li>
              <li>Zapier and API integrations</li>
              <li>Multiple co-admins</li>
            </ul>

          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-sheet elevation="3" outlined color="grey darken-3 white--text" class="pa-4">
            <h3 class="text-center">Enterprise</h3>
            <p>Enterprise plan with advanced customization and integrations options.</p>
            <v-btn v-if="org.license === 'ENTERPRISE'" disabled text color="grey">Current</v-btn>
            <v-btn v-else text color="green" to="/contact">Contact Sales</v-btn>
            <hr/>
            <span class="large">Contact Us</span>
            <hr/>
            <ul class="check-list">
              <li class="font-weight-bold">All features from Pro plan</li>
              <li>Unlimited users</li>
              <li>SSO (Single Sign-On integrations)</li>
              <li>Custom domain</li>
              <li>Custom app</li>
              <li>Full control over design and email templates</li>
            </ul>
          </v-sheet>
        </v-col>
      </v-row>

      <v-card-text>
        <h3>Add-Ons</h3>
        <v-row>
          <v-col cols="12" md="6">
            Select your currency: 
            <v-btn-toggle v-model="currency" mandatory class="multiline-btns" >
              <v-btn v-for="cur in currencies" :key="cur" :value="cur" small>
                {{cur}}
              </v-btn>
            </v-btn-toggle>

          </v-col>
          <v-col cols="12" md="6">
            <v-btn text @click="promptForCoupon"><v-icon color="grey" small class="mr-2">fa-tag</v-icon> Add a Coupon</v-btn>
          </v-col>
        </v-row>
        <v-sheet elevation="3" color="grey lighten-4 mb-8" class="px-4 py-1 pricing-details">
          <h3>Full-Service Package</h3>
          <p>Add the Full-Service Package to have us do the configuration for your event plus an additional onboarding session.</p>
          <hr/>
          <v-row>
            <v-col class="grow">
              <span class="large">{{currency}} {{products.find(x=>x.key=='product-full-service').prices[currency]}}</span><span class="small">one-time<br/>per event</span>
            </v-col>
            <v-col class="shrink">
              <v-btn large color="primary" class="my-0" @click="purchaseProduct('product-full-service')">Purchase Now</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet elevation="3" color="grey lighten-4" class="pa-4 py-1 pricing-details">
          <h3>Pre-designed Badges</h3>
          <p>Get a set of beautifully designed badges you can easily use in your challenge to reward participants on reaching a certain goal.</p>
          <hr/>
          <v-row>
            <v-col class="grow">
              <span class="large">{{currency}} {{products.find(x=>x.key=='product-badge-pack').prices[currency]}}</span><span class="small">one-time<br/>purchase</span>
            </v-col>
            <v-col class="shrink">
              <v-btn large color="primary" class="my-0" @click="purchaseProduct('product-badge-pack')">Purchase Now</v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>

    </v-card>   
    <v-dialog v-if="product" v-model="showQuantityDialog" max-width="400">
      <v-card>
        <v-card-title>{{ product.name }}</v-card-title>
        <v-card-text>
          <p>How many user seats would you want to purchase? You can always add more later on. Minimum purchase quantity is 10.</p>
          <p>Subscription will auto-renew until you cancel. You may cancel any time before the next payment term has started.</p>
          <!-- <p>Please note that you will see the name <strong>sodisp</strong> on the payment page and payment receipt. This is because {{ tenant.name }} is powered by the Sodisp Challenge Platform.</p> -->
          <v-text-field  v-if="false" :value="product.license" disabled label="License Type"/>
          <v-text-field v-if="false" :value="product.prices[currency]" disabled :label="`Price per user (${currency})`"/>
          <v-text-field v-if="coupon" :value="coupon" disabled label="Coupon" prepend-icon="fa-tag"/>
          <v-text-field v-model="quantity" prepend-icon="fa-users" type="number" min="1" label="Number of users" persistent-hint :hint="`At ${currency} ${product.prices[currency].toFixed(2)} per user`" />
      
          <v-input prepend-icon="fa-coins" class="mt-4 input-row" label="Currency" hide-details>
            <template>
              <v-btn-toggle v-model="currency" mandatory class="multiline-btns">
                <v-btn v-for="cur in currencies" :key="cur" :value="cur" small>
                  {{cur}}
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-input>

          <v-text-field v-model="total" prepend-icon="fa-tags" class="my-2" disabled :prefix="currency" label="Monthly Fee" hide-details/>
          <v-btn large block color="primary" class="mt-4" @click="purchasePlan" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">Start Purchase</v-btn>
          <p class="text-muted mt-2">Secure payment using Stripe.</p>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-dialog v-if="org" v-model="showAddSeatsDialog" max-width="400">
      <v-card>
        <v-card-title>{{ org.license | license }} Plan</v-card-title>
        <v-card-text>
          <p>You can increase or decrease the number of users you allow to participate in events part of your organization. Minimum purchase quantity is 10.</p>
          <p>When you're changing the number of users, you will receive a new invoice for the remainder of the subscription period with the updated data. E.g. when you add more seats halfway the month, these will be charged for half the price for the current month and then for full on the next invoice.</p>
          <v-text-field :value="org.max_users" prepend-icon="fa-users" disabled readonly type="number" min="1" label="Current number of seats" />
          <v-text-field v-model="quantity" prepend-icon="fa-users" type="number" min="1" :label="`New number of seats`" />
          <v-btn large block color="primary" class="mt-4" @click="purchasePlan" :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading">Upgrade Subscription</v-btn>
        </v-card-text>

      </v-card>
    </v-dialog>

      <div class="secondary white--text py-6 py-md-12 mt-4">
        <div class="container text-center">
          <v-row>
            <v-col cols="12" md="12">
              <h1 class="subtitle">Questions? We're here to help.</h1>
              <p class="mb-0 mt-4">
                <v-btn class="mb-4 mr-4" color="white" to="/contact">Contact Us</v-btn>
              </p>
            </v-col>
          </v-row>
        </div>
      </div>

      <div id="page-customers" class="mt-0 blob-bg white--text mt-8">
        <div class="container pb-0">
          <v-row>
            <v-col class="pb-4">
              <h1 class="headline">Trusted by global corporations</h1>
              <p>
                {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
              </p>
              <SectionCustomers :showcase="false" corporate/>
            <v-col class="text-center" cols="12" md="12">
              <h1 class="subtitle">Get started today!</h1>
              <p class="mb-0">
                Over <strong>1,087 events</strong> have been created already. Join today!
              </p>
              <p class="mb-0 mt-4">
                <v-btn class="mb-4 mr-4" color="white" :to="{name: 'orgmanagerCreate'}">Create your organization</v-btn>
                <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
              </p>
            </v-col>

            </v-col>
          </v-row>
        </div>
      </div>
      <div class="grey lighten-4">
      <v-container class="pb-0">
        <v-row>
          <v-col cols="4" md="2">
            <img src="https://sodisp.imgix.net/content/quote-drift-kelly.jpg?w=200&h=200&fit=facearea&faceindex=1&facepad=4&mask=ellipse&mask-bg=f5f5f5" style="max-width:100%;" />
            <p>
              Kelly Reardon<br/>
              <i>Drift Experience Manager @ <a href="https://drift.com" target="_blank">Drift</a></i>
            </p>
          </v-col>
          <v-col cols="8" md="10">
            <blockquote>
              "At Drift we had a goal of 10% participation in our first challenge and had over 20% of our employees signed up. [...] This is by far the smoothest platform I have used to date and our Drifters are already asking when the next challenge will take place."
            </blockquote>
          </v-col>
        </v-row>
      </v-container>
      </div>

  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import checkoutService from "@/services/checkoutService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import store from "@/store";
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "License",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      quantity: 10,
      showQuantityDialog: false,
      showAddSeatsDialog: false,
      status: null,
      licenseInfo: null,
      paymentStatus: null,
      coupon: null,
      product: null,
      products: [
        {
          key: 'subscription-month-basic',
          name: 'STANDARD subscription (monthly)',
          type: 'subscription',
          license: 'BASIC',
          prices: {
            USD: 1.5,
            EUR: 1.5,
            GBP: 1.4,
          }
        },
        {
          key: 'subscription-month-pro',
          name: 'PRO subscription (monthly)',
          type: 'subscription',
          license: 'PRO',
          prices: {
            USD: 2.5,
            EUR: 2.5,
            GBP: 2.2,
          }
        },
        {
          key: 'product-full-service',
          name: 'Full-Service Package',
          type: 'product',
          prices: {
            USD: 250,
            EUR: 250,
            GBP: 220,
          }
        },
        {
          key: 'product-badge-pack',
          name: 'Badge Pack',
          type: 'product',
          prices: {
            USD: 100,
            EUR: 100,
            GBP: 100,
          }
        },
      ],
      currency: 'USD',
      currencies: ['USD', 'EUR', 'GBP'],
    };
  },
  async mounted() {
    this.status = this.$route.query.status;
    await this.loadState();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadState();
    });
  },
  methods: {
    async getOrg(id) {
      await this.getLicenseInfo();
    },

    async loadState() {
      if (this.user) {
        await this.getOrg(this.$route.params.id);
        if (this.status === 'check-subscription') {
          await this.getSubscriptionStatus();
        }
        if (this.status === 'check-payment') {
          await this.getPaymentStatus();
        }
      }
    },

    async getSubscriptionStatus() {
      if (!this.org) {
        return;
      }

      await this.getLicenseInfo();
      console.log('Checking subscription status, goal', this.$route.query.goal, ', current:', this.licenseInfo&&this.licenseInfo.max_capacity);
      if (this.licenseInfo && this.licenseInfo.max_capacity == this.$route.query.goal) {
        this.paymentStatus = { status: 'OK' }; 
        this.status = null;
      }
      else {
        setTimeout(this.getSubscriptionStatus, 5 * 1000);
      }
    },

    async getPaymentStatus() {
      if (!this.org) {
        return;
      }

      this.paymentStatus = (await checkoutService.getOrgStatus(this.org.id)).data;
      
      if (this.paymentStatus.status === 'INFO') {
        // schedule a next refresh in a few seconds
        setTimeout(this.getPaymentStatus, 5 * 1000);
      }
      else if (this.paymentStatus.status === 'OK') {
        this.$analytics.event('purchase', {
          currency: 'USD',
          value: this.paymentStatus.value,
          transaction_id: this.paymentStatus.id,
        });
      }
    },

    async promptForCoupon() {
      this.coupon = prompt('Please enter your coupon code if you have one. It will be applied on check-out.');
      
      if (this.coupon) {
        this.$helpers.toastResponse(this, { status: 'OK'}, 'Your coupon will be applied on the check-out page.')
      }
    },

    async redirectToPaymentPortal() {
      const response = (await checkoutService.getOrgPaymentPortalLink(this.org.id)).data;
      
      if (response.status === 'OK') {
        const stripePortalUrl = response.msg;
        window.location.href = stripePortalUrl
      }
    },

    async getLicenseInfo() {
      if (this.org) {
        this.licenseInfo = (await orgManagerService.getLicenseStatus(this.org.id)).data;
        this.quantity = this.org.max_users || 10;
      }
    },

    async selectPlan(productKey) {
      this.product = this.products.find(x => x.key === productKey);
      this.showQuantityDialog = true;
    },

    async addSeats(productKey) {
      this.product = this.products.find(x => x.key === productKey || x.license === productKey);
      this.quantity = this.org.max_users || 10;
      this.showAddSeatsDialog = true;
    },
    async useFree() {
      //this.selectedLicense = license;
      //this.showQuantityDialog = true;
    },
    async purchasePlan() {
      const request = {
        product: this.product.key,
        initial_quantity: this.quantity,
        //full_service: this.fullService,
        coupon: this.coupon,
        currency: this.currency,
        group_id: this.org.id,
      };
      this.$analytics.event('begin_checkout', {
        currency: 'USD',
        value: this.total,
      });

      this.isLoading = true;
      const response = (await checkoutService.createOrgPaymentSession(request)).data;
      this.valid = true;
      this.isLoading = false;
      window.location.href = response.url
    },

    async purchaseProduct(productKey) {
      var product = this.products.find(x => x.key === productKey);
      var currency = this.currency || 'USD';
      var price = product.prices[currency];
      console.log('Starting purchase', product, 'currency', currency, 'price', price);
      const request = {
        product: productKey,
        initial_quantity: 1,
        coupon: this.coupon,
        currency: this.currency,
        group_id: this.org.id,
      };
      this.$analytics.event('begin_checkout', {
        currency: currency,
        value: price,
      });

      this.isLoading = true;
      const response = (await checkoutService.createOrgProductSession(request)).data;
      this.valid = true;
      this.isLoading = false;
      window.location.href = response.url
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: `License`, disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    pricePerUser() {
      if (!this.product || !this.currency) {
        return 0;
      }
      return this.product.prices[this.currency];
    },
    total() {
      return (this.quantity * this.pricePerUser);
    },

    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    org() {
      this.loadState();
    },
  }
};
</script>
<style lang="scss">
  .pricing-details {
    .v-sheet { border-radius: 10px !important; text-align: center;; }
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .small { font-size: 75%; display: inline-block; text-align: left;}
    .large { font-size: 200%;}
    .large + .small { margin-left: 5px; }
    .popular { border: solid 5px #00B46E !important; }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
</style>

